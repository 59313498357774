import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Projects`}</h2>
    <ProjectCard title="Eat" link="https://www.google.com/search?q=how+to+eat%3F" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  Eating is the ingestion of food, typically to provide a heterotrophic organism with energy and to allow for growth. Animals and other heterotrophs must eat in order to survive — carnivores eat other animals, herbivores eat plants, omnivores consume a mixture of both plant and animal matter, and detritivores eat detritus.
    </ProjectCard>
    <ProjectCard title="Sleep" link="https://www.google.com/search?q=how+to+sleep%3F" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
  Sleep is a naturally recurring state of mind and body, characterized by altered consciousness, relatively inhibited sensory activity, reduced muscle activity and inhibition of nearly all voluntary muscles during rapid eye movement (REM) sleep,and reduced interactions with surroundings
    </ProjectCard>
    <ProjectCard title="Bath" link="https://www.google.com/search?q=how+to+bath%3F" bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)" mdxType="ProjectCard">
  Bathing is the washing of the body with a liquid, usually water or an aqueous solution, or the immersion of the body in water
    </ProjectCard>
    <ProjectCard title="Run from/for Life" link="https://www.sleepfoundation.org/" bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)" mdxType="ProjectCard">
  I couldn't find, Nobody knows how to run from/for life.
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      