import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`ABU SAYED`}</h1>
    <p>{`Just an average guy with high aims and higher hopes! A human being in search of humanity on earth! A son a parents.  Negative by Blood!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      