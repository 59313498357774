import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Contact: `}<a parentName="h2" {...{
        "href": "mailto:hi@abusayed.dev"
      }}>{`Email`}</a>{` `}<a parentName="h2" {...{
        "href": "https://www.twitter.com/abusayed0206"
      }}>{`Twitter`}</a>{` `}<a parentName="h2" {...{
        "href": "https://t.me/lrsayed"
      }}>{`Telegram`}</a></h2>
    <h4>{`For Idle Lad/Lady: `}<a parentName="h4" {...{
        "href": "https://blog.abusayed.dev"
      }}>{`Blog`}</a></h4>
    <br />
    <h4>{`How to show `}<strong parentName="h4"><em parentName="strong">{`PURE LOVE`}</em></strong>{` ?`}</h4>
    <h6>{`Send post Letters, Post Cards & Gifts to this address:`}</h6>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`MD ABU SAYED`}</strong>{` `}<br />{`
`}<em parentName="p">{`Shaheed Mohammad Shah Hall, Chittagong University of Engineering & Technology, Chittagong`}</em>{` `}<br />{`
`}<em parentName="p">{`PostCode: 4349`}</em>{` `}<br />{`
`}<em parentName="p">{`Phone:  +880 963839 3931`}</em></p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      